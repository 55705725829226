"use client";

import LayoutLoader from "@/ui/layout/layout-loader";
import { SessionProvider } from "next-auth/react";
import { ReactNode, Suspense } from "react";

export default function AdminLayout({ children }: { children: ReactNode }) {
  return (
    <SessionProvider>
      <Suspense fallback={<LayoutLoader />}>
        {children}
      </Suspense>
    </SessionProvider>
  );
}
